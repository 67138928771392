import PropTypes from 'prop-types';
import { forwardRef, useState, useEffect, useMemo } from 'react';

// material-ui
import { DataGrid } from '@mui/x-data-grid';

// ==============================|| CUSTOM DATA GRID WITH DYNAMIC X SCROLL ||============================== //

const DataGridWithDynamicXScroll = forwardRef(({ sx = {}, ...others }, ref) => {
  const { rows, loading } = others;
  const hasRows = Boolean(rows?.length);

  const useIsInViewport = (el) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), []);

    useEffect(() => {
      if (el) {
        observer.observe(el);
      }

      return () => {
        observer.disconnect();
      };
    }, [el, observer]);

    return isIntersecting;
  };

  const [footerElement, setFooterElement] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFooterElement(document.getElementsByClassName('MuiDataGrid-footerContainer')[0]);
    }, 100); // Delay of 100ms to allow rendering to stabilize

    return () => clearTimeout(timeout);
  }, [rows]); // Ensure footerElement is set after rows are rendered

  const isDataGridFooterInViewport = useIsInViewport(footerElement);

  return (
    <DataGrid
      ref={ref}
      sx={{
        '& .MuiDataGrid-main': {
          transform: `rotateX(${isDataGridFooterInViewport || !hasRows ? 0 : 180}deg)`
        },
        '& .MuiDataGrid-virtualScroller': {
          transform: `rotateX(${isDataGridFooterInViewport || !hasRows ? 0 : 180}deg)`
        },
        '& .MuiDataGrid-scrollbar': {
          position: `${isDataGridFooterInViewport || !hasRows ? 'absolute !important' : 'unset !important'}`
        },
        ...sx
      }}
      {...others}
      hideFooter={Boolean(loading)}
    />
  );
});

DataGridWithDynamicXScroll.propTypes = {
  sx: PropTypes.object
};

export default DataGridWithDynamicXScroll;
