import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Dialog, DialogContent, DialogTitle, Typography, Grid, List, ListItem, ListItemText, Tooltip } from '@mui/material';

import DataGridWithDynamicXScroll from 'ui-component/data-grids/DataGridWithDynamicXScroll';
import callAzureFunction from 'utils/call-azure-function';
import handleError from 'utils/handle-error';
import { getDateWithCustomTime } from 'utils/booking';
import { useGetEntityName } from 'utils/entities';
import { HelpOutline } from '@mui/icons-material';

const ActivityLogsDialog = ({ open, setOpen, bookingId }) => {
  const [loading, setLoading] = useState(false);
  const [bookingConfirmationReminders, setBookingConfirmationReminders] = useState([]);

  const managerEntityName = useGetEntityName('manager')?.toLowerCase();
  const bookingEntityName = useGetEntityName('booking')?.toLowerCase();
  const externalUserEntityName = useGetEntityName('externalUser')?.toLowerCase();

  const [rowCount, setRowCount] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0
  });

  const [sortModel, setSortModel] = useState([{ field: 'scheduledSendAt', sort: 'asc' }]);

  const getBookingConfirmationReminders = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callAzureFunction({ url: 'activity-logs', method: 'get', params: { bookingId } });
      const { rows, count } = response.data;

      setRowCount(count);
      setBookingConfirmationReminders(rows);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [bookingId]);

  const getActivityDetails = (props) => {
    const activities = [];

    const {
      isChangeInManager,
      isChangeInStatus,
      isChangeInSchedule,
      isChangeInService,
      isChangeInLocation,
      isChangeToAnyManagerAtLocation,
      bookingState
    } = props || {};

    const { date, startHours, startMinutes, endHours, endMinutes, service, location, manager, status } = bookingState || {};

    if (isChangeInStatus) {
      activities.push(`The status has been updated to ${status}`);
    }

    if (isChangeInManager) {
      activities.push(
        `The ${bookingEntityName} has been re-assigned to another ${managerEntityName} - ${manager?.firstName} ${bookingState?.manager?.lastName}`
      );
    }

    if (isChangeInService) {
      activities.push(`The ${bookingEntityName} service has been updated into ${service?.name}`);
    }

    if (isChangeToAnyManagerAtLocation) {
      activities.push(`The ${bookingEntityName} has been re-assigned to any available ${managerEntityName} at location`);
    }

    if (isChangeInLocation) {
      activities.push(`The ${bookingEntityName} location has been changed to ${location?.name}`);
    }

    if (isChangeInSchedule) {
      const formattedDate = date ? dayjs(date).format('DD/MM/YYYY') : null;
      const formattedStartTime = dayjs(getDateWithCustomTime(startHours, startMinutes)).format('hh:mm a');
      const formattedEndTime = dayjs(getDateWithCustomTime(endHours, endMinutes)).format('hh:mm a');
      const details = `The ${bookingEntityName} is rescheduled to ${formattedDate} from ${formattedStartTime} to ${formattedEndTime}`;

      activities.push(details);
    }

    return activities;
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Log ID',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Typography
          textTransform="capitalize"
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%'
          }}
        >
          {params.row.id}
        </Typography>
      )
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => {
        const { description } = params.row;

        return (
          <Typography
            textTransform="capitalize"
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}
          >
            {description}
          </Typography>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Activity',
      sortable: false,
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        const { properties } = params.row;

        const props = JSON.parse(properties);

        const activities = getActivityDetails(props);

        return (
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%'
            }}
          >
            {activities.map((activity, index) => (
              <ListItem key={index}>
                <ListItemText primary={activity} />
              </ListItem>
            ))}
          </List>
        );
      }
    },
    {
      field: 'userIdentity',
      headerName: 'Performed By',
      sortable: false,
      // flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        const { Manager: manager } = params.row;

        return (
          <Typography
            textTransform="capitalize"
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}
          >
            {`${manager?.firstName} ${manager?.lastName}`}
          </Typography>
        );
      }
    },
    {
      field: 'userType',
      headerName: 'User Type',
      sortable: false,
      minWidth: 200,
      renderCell: (params) => {
        const { Manager: manager } = params.row;

        return (
          <Typography
            textTransform="capitalize"
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}
          >
            {manager?.isExternalUser ? 'External User' : `${managerEntityName}`}
          </Typography>
        );
      }
    },
    {
      field: 'createdAt',
      headerName: 'Time of Activity',
      sortable: false,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%'
          }}
        >
          {dayjs(params.row.createdAt).format('YYYY-MM-DD hh:mm:ss a')}
        </Typography>
      )
    }
  ];

  useEffect(() => {
    getBookingConfirmationReminders();
  }, [getBookingConfirmationReminders]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl" fullWidth>
      <DialogTitle variant="h3" display="flex" alignItems="center" gap={1}>
        Activity Logs{' '}
        <Tooltip
          title={`Only ${bookingEntityName} changes made by ${managerEntityName}/ ${externalUserEntityName} are logged and displayed here.`}
          placement="right"
        >
          <HelpOutline sx={{ cursor: 'pointer' }} />
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sx={{ display: 'flex', height: '100%', overflow: 'auto' }}>
          <DataGridWithDynamicXScroll
            getRowHeight={() => 'auto'}
            disableColumnMenu
            loading={loading}
            rows={bookingConfirmationReminders}
            columns={columns}
            density="comfortable"
            disableRowSelectionOnClick
            disableColumnFilter
            autoHeight
            rowCount={rowCount}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ActivityLogsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  bookingId: PropTypes.string
};

export default ActivityLogsDialog;
