import { createSlice } from '@reduxjs/toolkit';
import { DATA_SOURCES } from 'constants/appDataSources';

export const initialState = {
  selectedId: DATA_SOURCES.default.id,
  dataSources: DATA_SOURCES,
  disabled: false
};

const appDataSourceSlice = createSlice({
  name: 'appDataSource',
  initialState,
  reducers: {
    setAppDataSourceId: (state, action) => {
      state.selectedId = action.payload;
    },
    setAppDataSources: (state, action) => {
      state.dataSources = action.payload;
    },
    setAppDataSourceDisabled: (state, action) => {
      state.disabled = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAppDataSourceId, setAppDataSources, setAppDataSourceDisabled } = appDataSourceSlice.actions;

export default appDataSourceSlice.reducer;
