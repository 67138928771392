// dayjs
import dayjs from 'dayjs';

// redux
import { combineReducers, configureStore } from '@reduxjs/toolkit';

// redux-persist
import { persistStore, persistReducer, createMigrate, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducers
import customizationReducer from './features/customizationSlice';
import userReducer, { initialState as initialStateUser } from './features/userSlice';
import respondentReducer from './features/respondentSlice';
import appConfigReducer, { initialState as initialStateAppConfig } from './features/appConfigSlice';
import appDataSourceReducer, { initialState as initialStateAppDataSource } from './features/appDataSourceSlice';
import snackbarReducer from './features/snackbarSlice';
import calendarFiltersReducer, { initialState as initialStateCalendar } from './features/calendarFiltersSlice';
import alertReducer from './features/alertSlice';
import triggerAlertBannerReducer from './features/triggerAlertBannerSlice';

// constant
import { calendarModes } from 'constants/calendar';

// ==============================|| REDUX - MAIN STORE ||============================== //

// items to reset when a redux reset is requested i.e. on logout
const resetSlices = ['customization', 'respondent', 'user', 'triggerAlertBanner'];

// items to persist.
// persisting items will allow them to work between multiple tabs, or across multiple sessions
// ensure any items you do not want to persist after a session is complete are added to the resetSlices above
const persistSlices = ['user', 'appConfig', 'appDataSource', 'calendarFilters'];

// redux persist migrations
// https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
const migrations = {
  // initial migration
  0: (state) => ({
    ...state,
    // reset all persisted slices
    user: initialStateUser,
    appConfig: initialStateAppConfig,
    calendarFilters: initialStateCalendar
  }),
  // add more migrations as needed
  // ...
  1: (state) => ({
    ...state,
    calendarFilters: {
      ...state.calendarFilters,
      calendarManagerFilter: []
    }
  }),
  2: (state) => ({
    ...state,
    calendarFilters: {
      ...state.calendarFilters,
      calendarModeFilter: calendarModes.ByLocation
    }
  }),
  3: (state) => ({
    ...state,
    calendarFilters: {
      ...state.calendarFilters,
      calendarModeFilter: calendarModes.ByLocation,
      calendarMinTimeFilter: state.calendarFilters.calendarMinTimeFilter
        ? dayjs(state.calendarFilters.calendarMinTimeFilter).format('HH:mm')
        : null,
      calendarMaxTimeFilter: state.calendarFilters.calendarMinTimeFilter
        ? dayjs(state.calendarFilters.calendarMaxTimeFilter).format('HH:mm')
        : null
    }
  }),
  4: (state) => ({
    ...state,
    appDataSource: initialStateAppDataSource
  })
};

const persistConfig = {
  key: 'root',
  // set version to latest migration number
  version: 4,
  migrate: createMigrate(migrations, { debug: false }),
  // save to local storage, other storage options https://github.com/rt2zz/redux-persist#storage-engines
  storage,
  whitelist: persistSlices
};

const rootReducer = combineReducers({
  customization: customizationReducer,
  user: userReducer,
  respondent: respondentReducer,
  snackbar: snackbarReducer,
  appConfig: appConfigReducer,
  appDataSource: appDataSourceReducer,
  calendarFilters: calendarFiltersReducer,
  alert: alertReducer,
  triggerAlertBanner: triggerAlertBannerReducer
});

const resetActionReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    for (const sliceName of resetSlices) {
      state[sliceName] = undefined;
    }
  }
  return rootReducer(state, action);
};

// enhanced reducer with configuration to persist the state
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
const persistedReducer = persistReducer(persistConfig, resetActionReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

const persistor = persistStore(store);

export { store, persistor };
