import PropTypes from 'prop-types';

import { useCallback, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Stack } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import HelpSection from './HelpSection';
import userTypes from 'constants/userTypes';

// redux
import { useSelector } from 'react-redux';

import ProfileSection from './ProfileSection';
import SettingsSection from './SettingsSection';
import NotificationSection from './NotificationSection';
import headerRightBrand from 'assets/images/brand/header_right_logo.svg';

import callAzureFunctionPublic from 'utils/call-azure-function-public';
import callAzureFunction from 'utils/call-azure-function';
import handleError from 'utils/handle-error';

// assets
import { IconMenu2 } from '@tabler/icons-react';
import DataSourceMenu from './DataSourceMenu';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const userData = useSelector((state) => state.user?.data);

  const [unreadNotifCount, setUnreadNotifCount] = useState(0);

  const [webSocket, setWebSocket] = useState(null);

  // Auxiliary state dedicated to triggering the getBookings() from the WebSocket event
  const [triggerGetUnreadNotifCount, setTriggerGetUnreadNotifCount] = useState(false);

  const getUnreadNotifCount = useCallback(async () => {
    try {
      const response = await callAzureFunction({ url: 'user-notifications-count-unread', method: 'get' });
      setUnreadNotifCount(response?.data);
    } catch (error) {
      handleError(error);
    }
  }, []);

  const handleWSOpen = () => {
    console.info('WebSocket: Connection opened');
  };

  const handleWSClose = () => {
    console.info('WebSocket: Connection closed');
  };

  const handleWSError = (event) => {
    console.error('WebSocket: Error', event);
  };

  const handleWSMessage = (event) => {
    console.info('WebSocket: Message received', event.data);
    setTriggerGetUnreadNotifCount((prev) => !prev);
  };

  const webSocketConnect = useCallback(async () => {
    let socket;
    try {
      const result = await callAzureFunctionPublic({
        url: `/websocket_connect_notifications`,
        method: 'get'
      });
      const wsUrl = result.data.webSocketUrl;
      if (wsUrl) {
        socket = new WebSocket(wsUrl);

        socket.addEventListener('open', handleWSOpen);
        socket.addEventListener('close', handleWSClose);
        socket.addEventListener('error', handleWSError);
        socket.addEventListener('message', handleWSMessage);

        setWebSocket(socket);
      }
    } catch (error) {
      handleError(error);
    }
  }, []);

  const webSocketDisconnect = useCallback(() => {
    if (webSocket) {
      console.info('WebSocket: Disconnecting');
      webSocket.removeEventListener('open', handleWSOpen);
      webSocket.removeEventListener('close', handleWSClose);
      webSocket.removeEventListener('error', handleWSError);
      webSocket.removeEventListener('message', handleWSMessage);
      webSocket.close();
      setWebSocket(null);
    }
  }, [webSocket]);

  useEffect(() => {
    webSocketConnect();
    return webSocketDisconnect;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // call getBookings() whenever the auxiliary state is updated from the websocket event
  useEffect(() => {
    getUnreadNotifCount();
  }, [triggerGetUnreadNotifCount, getUnreadNotifCount]);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              '&:hover': {
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText
              }
            }}
            onClick={handleLeftDrawerToggle}
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      <Box sx={{ ml: 4 }}>
        {/* menu for switching data source */}
        <DataSourceMenu />
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      {/* <IntroVideoSection /> */}
      <Box sx={{ mr: 4, mb: -0.5 }}>
        <img src={headerRightBrand} style={{ height: 30, filter: 'grayscale(30%)' }} alt="header right logo" />
      </Box>
      <Stack direction="row" alignItems="center" gap={1.5} sx={{ mt: 1.5 }}>
        <NotificationSection unreadNotifCount={unreadNotifCount} getUnreadNotifCount={getUnreadNotifCount} />
        <HelpSection />
        {userData?.type === userTypes.Admin && <SettingsSection />}
        <ProfileSection />
      </Stack>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
