// redux
import { useSelector } from 'react-redux';

import callAzureFunctionPublic from './call-azure-function-public';
import { defaultAppConfig } from 'constants/appConfig';

const useBookingConfirmationEmailHasAppointmentEndTime = () => {
  const appConfig = useSelector((state) => state.appConfig?.data);
  return Boolean(appConfig?.bookingConfirmationText?.emailBody?.includes('{appointmentEndTime}'));
};

const getAppConfigurationData = async (appDataSourceId = null) => {
  const data = {};

  const res = await callAzureFunctionPublic({
    url: `/public/app-configurations`,
    method: 'get',
    appDataSourceId
  });

  const config = res.data?.config ? JSON.parse(res.data.config) : {};

  // merge config with default one
  Object.keys(defaultAppConfig).forEach((key) => {
    data[key] = { ...defaultAppConfig[key], ...config[key] };
  });

  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { useBookingConfirmationEmailHasAppointmentEndTime, getAppConfigurationData };
