import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { DATA_SOURCE_ROUTES, DATA_SOURCES } from 'constants/appDataSources';

const useAppDataSource = () => {
  const location = useLocation();
  const appDataSource = useSelector((state) => state?.appDataSource) || {};

  const { appDataSourceVisible, appDataSourceDisabled } = useMemo(
    () =>
      DATA_SOURCE_ROUTES.reduce(
        (result, route) => {
          if (route.path.test(location.pathname)) {
            result.appDataSourceVisible = true;
            if (route.disabled) {
              result.appDataSourceDisabled = true;
            }
          }
          return result;
        },
        { appDataSourceVisible: false, appDataSourceDisabled: false }
      ),
    [location.pathname]
  );

  const appDataSourceId = appDataSource?.selectedId || DATA_SOURCES.default.id;
  const appDataSourceRemote = appDataSourceId !== 'default';

  return {
    appDataSourceId,
    appDataSourceVisible,
    appDataSourceDisabled: appDataSourceDisabled || Boolean(appDataSource.disabled),
    appDataSourceRemote
  };
};

export default useAppDataSource;
