import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Button, Dialog, DialogContent, DialogTitle, Typography, Grid, Stack } from '@mui/material';

import { getDateWithCustomTime } from 'utils/booking';
import DataGridWithDynamicXScroll from 'ui-component/data-grids/DataGridWithDynamicXScroll';
import callAzureFunction from 'utils/call-azure-function';
import handleError from 'utils/handle-error';

const ScheduledNotificationsDialog = ({ open, setOpen, bookingId }) => {
  const [loading, setLoading] = useState(false);
  const [bookingConfirmationReminders, setBookingConfirmationReminders] = useState([]);

  const [rowCount, setRowCount] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0
  });
  const [sortModel, setSortModel] = useState([{ field: 'availableAt', sort: 'asc' }]);

  const getBookingConfirmationReminders = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callAzureFunction({ url: 'booking-confirmation-reminders', method: 'get', params: { bookingId } });
      const { rows, count } = response.data;

      setRowCount(count);
      setBookingConfirmationReminders(rows);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [bookingId]);

  const handleSendConfirmationReminderNow = async (id) => {
    try {
      setLoading(true);
      await callAzureFunction({ url: 'booking-confirmation-reminders/send', method: 'post', data: { id } });
      await getBookingConfirmationReminders();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelConfirmationReminderNow = async (id) => {
    try {
      setLoading(true);
      await callAzureFunction({ url: 'booking-confirmation-reminders/cancel', method: 'post', data: { id } });

      await getBookingConfirmationReminders();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: 'schedule',
      headerName: 'Schedule',
      sortable: false,
      flex: 1,
      display: 'flex',
      minWidth: 120,
      renderCell: (params) => {
        const { hours, minutes, day } = params.row.ConfirmationReminder || {};

        const scheduledTime = dayjs(getDateWithCustomTime(hours, minutes, 0, 0)).format('hh:mm:a');
        return (
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {`Sends at ${scheduledTime} of Day ${day}`}
          </Typography>
        );
      }
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => (
        <Typography
          textTransform="capitalize"
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%'
          }}
        >
          {params.row.ConfirmationReminder?.type}
        </Typography>
      )
    },
    {
      field: 'messageTemplate',
      headerName: 'Confirmation Reminder Text',
      sortable: false,
      flex: 1,
      display: 'flex',
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {params.row.ConfirmationReminder?.messageTemplate}
        </Typography>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        const status = params.row.status?.toLowerCase();

        return (
          <>
            <Typography
              textTransform="capitalize"
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                justifyContent: 'space-between'
              }}
            >
              {status}
            </Typography>
          </>
        );
      }
    },
    {
      field: 'availableAt',
      headerName: 'Send Date and Time',
      sortable: false,
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => {
        const { availableAt } = params.row;

        const formattedDate = dayjs(availableAt)
          .tz(dayjs.tz.guess()) // Convert to the user's local timezone
          .format('YYYY-MM-DD hh:mm A');

        return (
          <Typography
            textTransform="capitalize"
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}
          >
            {formattedDate}
          </Typography>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      flex: 0.5,
      minWidth: 200,
      maxWidth: 200,
      renderCell: (params) => {
        const { status } = params.row;

        return (
          <Stack sx={{ height: '100%' }} justifyContent="center" alignItems="center">
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  handleSendConfirmationReminderNow(params.row.id);
                }}
                disabled={loading}
              >
                {status === 'COMPLETED' ? 'Resend' : 'Send Now'}
              </Button>
              {status === 'PENDING' && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    handleCancelConfirmationReminderNow(params.row.id);
                  }}
                >
                  Cancel
                </Button>
              )}
            </Stack>
          </Stack>
        );
      }
    }
  ];

  useEffect(() => {
    getBookingConfirmationReminders();
  }, [getBookingConfirmationReminders]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
      <DialogTitle variant="h3">Scheduled Notifications</DialogTitle>
      <DialogContent>
        <Grid item xs={12} sx={{ display: 'flex', height: '100%', overflow: 'auto' }}>
          <DataGridWithDynamicXScroll
            disableColumnMenu
            loading={loading}
            rows={bookingConfirmationReminders}
            columns={columns}
            density="comfortable"
            disableRowSelectionOnClick
            disableColumnFilter
            autoHeight
            rowCount={rowCount}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ScheduledNotificationsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  bookingId: PropTypes.string
};

export default ScheduledNotificationsDialog;
