const handlePreventKeyDown = (event) => {
  // Disallow non-numeric characters, decimal points, and negative signs
  if (event.key === '-' || (event.key.length === 1 && typeof event.key === 'string' && !/[0-9]/.test(event.key))) {
    event.preventDefault();
  }
};

const capitalizeFirstLetter = (string) => {
  if (!string || typeof string !== 'string') {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

const roundOffToTwoDecimals = (num) => Math.round(num * 100) / 100;

const computeTotals = (priceItems) => {
  const totals = priceItems.reduce((acc, curr) => acc + (parseFloat(curr?.price) || 0), 0);

  return roundOffToTwoDecimals(totals);
};

const computeTotalAmount = (amountItems) => {
  const totals = amountItems.reduce((acc, curr) => acc + (Number(curr.amount) || 0), 0);

  return roundOffToTwoDecimals(totals);
};

export { handlePreventKeyDown, capitalizeFirstLetter, roundOffToTwoDecimals, computeTotals, computeTotalAmount };
