import { useCallback, useEffect, useState } from 'react';
import './App.css';
import { SnackbarProvider } from 'notistack';

// stripe
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// redux
import { useSelector, useDispatch } from 'react-redux';

// mui
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import AppLogout from './AppLogout';
import handleError from 'utils/handle-error';
import callAzureFunctionPublic from 'utils/call-azure-function-public';
import { setAppConfigData } from 'store/features/appConfigSlice';
import { setAppDataSources } from 'store/features/appDataSourceSlice';
import { getImageUrl } from 'utils/entities';
import { notistackClasses, notistackCustomSnackbars } from 'views/utilities/NotistackClasses';
import { getAppConfigurationData } from 'utils/app-config';

// component
import BrandLogoLoader from 'ui-component/BrandLogoLoader';
import AlertDialog from 'ui-component/dialogs/AlertDialog';

const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_PUBLISHABLE_KEY);

const stripeElementOptions = {
  appearance: {
    theme: 'night',
    variables: {
      fontFamily: 'Sohne, system-ui, sans-serif',
      fontWeightNormal: '500',
      borderRadius: '8px',
      colorBackground: '#0A2540',
      colorPrimary: '#EFC078',
      accessibleColorOnColorPrimary: '#1A1B25',
      colorText: 'white',
      colorTextSecondary: 'white',
      colorTextPlaceholder: '#ABB2BF',
      tabIconColor: 'white',
      logoColor: 'dark'
    },
    rules: {
      '.Input': {
        backgroundColor: '#212D63',
        border: '1px solid var(--colorPrimary)'
      }
    },
    layout: {
      type: 'tabs',
      defaultCollapsed: false
    }
  }
};

// ==============================|| APP ||============================== //
const App = () => {
  const customization = useSelector((state) => state.customization);
  const { isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [loadingConfig, setLoadingConfig] = useState(true);

  const loadAppConfigurations = useCallback(async () => {
    try {
      setLoadingConfig(true);
      const data = await getAppConfigurationData();

      // udpate config in store
      dispatch(setAppConfigData({ data }));

      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }

      link.href = getImageUrl('favicon', data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingConfig(false);
    }
  }, [dispatch]);

  useEffect(() => {
    loadAppConfigurations();
  }, [loadAppConfigurations]);

  const loadEnvironments = useCallback(async () => {
    try {
      setLoadingConfig(true);
      const res = await callAzureFunctionPublic({
        url: `/environments`,
        method: 'get'
      });

      const appDataSources = res.data;
      if (appDataSources) {
        dispatch(setAppDataSources(appDataSources));
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingConfig(false);
    }
  }, [dispatch]);

  useEffect(() => {
    loadEnvironments();
  }, [loadEnvironments]);

  return (
    <>
      {loadingConfig ? (
        <Stack justifyContent="center" alignItems="center" sx={{ height: '100vh', backgroundColor: 'grey.200' }}>
          <BrandLogoLoader width={200} />
        </Stack>
      ) : (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SnackbarProvider Components={notistackCustomSnackbars} classes={notistackClasses} maxSnack={5}>
                  <Elements stripe={stripePromise} options={stripeElementOptions}>
                    <AppLogout>
                      <Routes isAuthenticated={isLoggedIn} />
                      <AlertDialog />
                    </AppLogout>
                  </Elements>
                </SnackbarProvider>
              </LocalizationProvider>
            </NavigationScroll>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
};

export default App;
